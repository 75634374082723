/**
 * Auth
 */
export const LOCAL_STORAGE_AUTH_USER = "auth.user";
export const LOCAL_STORAGE_AUTH_REAUTH = "auth.reauth";
export const LOCAL_STORAGE_AUTH_ATTEMPTS = "auth.attempts";
export const LOCAL_STORAGE_AUTH_REDIRECT = "auth.redirect";
export const LOCAL_STORAGE_AUTH_REF = "auth.ref";
export const LOCAL_STORAGE_AUTH_UTM = "auth.utm";
export const LOCAL_STORAGE_AUTH_ADMIN = "auth.admin";
export const LOCAL_STORAGE_AUTH_IMPERSONATE = "auth.impersonate";
export const LOCAL_STORAGE_AUTH_IMPERSONATE_ERROR = "auth.impersonate.error";

/**
 * Quests
 */
export const LOCAL_STORAGE_ACTIVE_QUESTS = "Quests.active";
