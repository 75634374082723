import { leftPadTo } from "./strings";

export function formatFloat(decimal: number, places: number) {
  if (isNaN(decimal)) return 0;
  return Number.parseFloat(`${Math.round(Number.parseFloat(`${decimal}e+${places}`))}e-${places}`);
}

export function isNumeric(str: string): boolean {
  return /^-?\d+$/.test(str);
}

export const roundToDecimalPlaces = (value: number, decimals: number) =>
  (Math.round(value * 100) / 100).toFixed(decimals);

export default [formatFloat];

const { locale } = Intl.NumberFormat().resolvedOptions();

/**
 * Formats a dollar amount - adding a dollar sign,
 * ensuring there are exactly 2 decimal places,
 * and adding commas
 * https://stackoverflow.com/questions/5340530/how-to-format-a-dollar
 * @param amount an amount of money as a number
 * @returns a formatted string
 */
export function formatDollarAmount(amount: number, hidePenniesForEvenDollars?: boolean): string {
  let str = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: hidePenniesForEvenDollars && amount === Math.floor(amount) ? 0 : 2,
  }).format(amount);
  return str;
}

export const addLeadingZeroes = (num: number, minSize: number) => leftPadTo(`${num}`, minSize, "0");

export const isEven = (num: number) => Math.round(num / 2) === num / 2;
export const isOdd = (num: number) => !isEven(num);
