import { IconButton, makeStyles, Menu } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloseRounded from "@material-ui/icons/CloseRounded";
import clsx from "clsx";
import { PropsWithChildren, useCallback } from "react";
import { SnoozeOption } from "../reclaim-api/Events";
import { Reschedulator, ReschedulatorProps, ReschedulatorRescheduleHandler } from "./Reschedulator";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    paper: {
      background: "white",
      padding: theme.spacing(2, 3),
      borderRadius: theme.spacing(),
    },
    closeButton: {
      position: "absolute",
      top: theme.spacing(-1),
      right: theme.spacing(-2),
    },
    closeButtonIcon: {
      fontSize: "inherit",
    },
  }),
  {
    classNamePrefix: "ReschedulatorPopper",
  }
);

export type ReschedulatorPopperJSSClassKey = keyof ReturnType<typeof useStyles>;

export type ReschedulatorPopperProps<S extends string = SnoozeOption> = {
  className?: string;
  classes?: Partial<ClassNameMap<ReschedulatorPopperJSSClassKey>>;
  open: boolean;
  onClose?(): void;
  anchorEl: HTMLElement;
  onReschedule: ReschedulatorRescheduleHandler<S>;
  snoozeOptions: readonly S[];
  reschedulatorProps?: Partial<Omit<ReschedulatorProps<S>, "onReschedule">>;
};

export function ReschedulatorPopper<S extends string = SnoozeOption>({
  className,
  classes: extClasses,
  open,
  onClose,
  onReschedule,
  anchorEl,
  reschedulatorProps,
  snoozeOptions,
}: PropsWithChildren<ReschedulatorPopperProps<S>>) {
  const classes = useStyles({
    classes: extClasses,
  }) as ClassNameMap<ReschedulatorPopperJSSClassKey>;

  const handleReschedule = useCallback<ReschedulatorRescheduleHandler<S>>(
    async (snooze) => {
      await onReschedule?.(snooze);
      onClose?.();
    },
    [onClose, onReschedule]
  );

  return (
    <Menu
      className={clsx(classes.root, className)}
      open={open}
      anchorEl={anchorEl}
      classes={{
        paper: classes.paper,
      }}
      onClose={onClose}
    >
      <Reschedulator<SnoozeOption>
        onReschedule={handleReschedule as ReschedulatorRescheduleHandler<SnoozeOption>}
        {...reschedulatorProps}
        snoozeOptions={snoozeOptions as readonly SnoozeOption[]}
      />
      <IconButton className={classes.closeButton} onClick={onClose} size="small">
        <CloseRounded className={classes.closeButtonIcon} />
      </IconButton>
    </Menu>
  );
}
