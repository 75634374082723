import { useUserContext } from "../context/UserContext";
import { useTeamState } from "../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { Team } from "../reclaim-api/team/Team";
import { User } from "../reclaim-api/Users";
import { DevRegetData } from "./dev.types";

export const runDevAction = (
  key: string,
  user: User,
  team: Team,
  args: Record<string, unknown>
): Promise<DevRegetData> =>
  fetch(`/api/dev/runDevAction`, {
    method: "POST",
    body: JSON.stringify({
      key,
      args: {
        user_id: user.id,
        team_id: team.id,
        ...args,
      },
    }),
  }).then((r) => r.json());

export const useRunDevAction = () => {
  const [{ user }] = useUserContext();
  const { team } = useTeamState();
  return useCallbackSafeRef(
    (key: string, args: Record<string, unknown>) => (user && team && runDevAction(key, user, team, args)) || undefined
  );
};
