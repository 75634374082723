import { ReactNode, VFC } from "react";
import { Tooltip, TooltipProps } from "./Tooltip";

export interface ReasonTipReason {
  test: boolean;
  message: ReactNode;
}

export function evaluateReason(reasons: ReasonTipReason[]): [boolean, ReactNode] {
  const index = reasons.findIndex(({ test }) => test);
  if (index === -1) return [false, undefined];
  return [true, reasons[index].message];
}

export type ReasonTipProps = Omit<TooltipProps, "children" | "title"> & {
  reasons: ReasonTipReason[];
  appendReasonToTitle?: boolean;
  title?: TooltipProps["title"];
  children: TooltipProps["children"] | ((hasReason: boolean, message: ReactNode) => TooltipProps["children"]);
};

export const ReasonTip: VFC<ReasonTipProps> = ({
  className,
  classes: extClasses,
  reasons,
  children,
  appendReasonToTitle,
  title,
  ...rest
}) => {
  const [hasReason, message] = evaluateReason(reasons);
  let tt: ReactNode;
  if (hasReason) tt = message;
  if (appendReasonToTitle && title && tt) tt = `${title} ${message}`;
  if (!tt) tt = title;

  children = typeof children === "function" ? children(hasReason, message) : children;

  return (
    <Tooltip
      title={tt || false}
      disableHoverListener={!tt}
      disableTouchListener={!tt}
      disableFocusListener={!tt}
      enterDelay={0}
      disabled={!hasReason && !title}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
