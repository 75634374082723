import { useUserContext } from "../context/UserContext";
import { useAccountsState } from "./atoms/useAccounts";
import { useTeamState } from "./atoms/useTeam";

export const useAppReady = (): boolean => {
  const [{ user, isAuthenticated }] = useUserContext();
  const { team } = useTeamState();
  const { initialized: accountsInitialized } = useAccountsState();

  return !!user && !!isAuthenticated && !!team && !!accountsInitialized;
}