import { ReactNode } from "react";
import { concat, filter, fromPromise, map, merge, pipe } from "wonka";
import { Override } from "../types/index";
import { dateToStr, strToDate } from "../utils/dates";
import { deserialize, upsert } from "../utils/wonka";
import { DayOfWeek, Weekdays } from "./Calendars";
import {
  AssistType as AssistTypeDto,
  DailyHabit as HabitDto,
  DailyHabitTemplate as DailyHabitTemplateDto,
  DayOfWeek as DayOfWeekDto,
  DefenseAggression as DefenseAggressionDto,
  HabitTemplateKey as HabitTemplateKeyDto,
  Recurrence as RecurrenceDto,
  RecurringAssignmentType,
  SubscriptionType,
  ThinPerson as ThinPersonDto,
  UserProfileDepartment as UserProfileDepartmentDto,
  UserProfileRole as UserProfileRoleDto,
} from "./client";
import { Category, EventColor, EventSubType, PrimaryCategory } from "./EventMetaTypes";
import { Recurrence } from "./OneOnOnes";
import { isHabit as isHabitPlanner, TaskOrHabitIdentifyingFields } from "./Planner";
import { Smurf } from "./Projects";
import { NotificationKeyStatus, nullable, TransformDomain } from "./types";
import { User, UserProfileDepartment, UserProfileRole } from "./Users";

export type HabitTemplateKey = `${HabitTemplateKeyDto}`;

export type HabitTemplate = Override<
  DailyHabitTemplateDto,
  {
    name: HabitTemplateKey;
    eventCategory?: PrimaryCategory;
    daysActive: DayOfWeek[];
    windowStart: string;
    idealTime: string;
    windowEnd: string;
    recurrence: Recurrence | null;
  }
>;

export const dtoToHabitTemplate = (dto: DailyHabitTemplateDto): HabitTemplate => ({
  ...dto,
  eventCategory: !!dto.eventCategory ? Category.get(dto.eventCategory as unknown as string) : undefined,
  name: dto.name as HabitTemplateKey,
  daysActive: dto.daysActive as unknown as DayOfWeek[],
  recurrence: !!dto.recurrence ? Recurrence.get(dto.recurrence) : null,
});

export const isHabit = (item: unknown): item is Habit => isHabitPlanner(item as TaskOrHabitIdentifyingFields);

// WARNING BEFORE EDITING: These values are copied from the server, this is what is set by the server when an undefined value is set
// Be careful changing these to make sure they are in sync with the server in /src/main/java/ai/reclaim/server/assist/TaskOrHabit.java
// TODO: Find a way to add this to OpenAPI (ma)
export const DefaultAutoDeclineText =
  "Hi! This is Reclaim, {name}'s virtual assistant. I'm sorry, but {name} has a commitment at this time, and it's one of the last open slots available to get it done. Can you find another time to meet?";
export const DefaultDefendedDescription =
  "Reclaim has blocked this time off for {name} to work on an important commitment. Reclaim defended this time because it's one of the last available in {name}'s schedule. Please find another time to meet with {name}.";

export enum DefenseAggression {
  None = "NONE",
  Low = "LOW",
  Default = "DEFAULT",
  High = "HIGH",
  Max = "MAX",
}

export const HABIT_DEFENSIVENESS_COPY: Record<DefenseAggression, { label: ReactNode; description: ReactNode }> = {
  NONE: { label: "Always free", description: "Habits will always show as free and available time on your calendar." },
  LOW: {
    label: "Least defensive",
    description:
      "Marks the Habit as busy when one slot remains for its minimum duration, or 30m before the Habit is scheduled to begin.",
  },
  DEFAULT: {
    label: "More defensive",
    description:
      "Marks the Habit as busy when two slots remain for its minimum duration, or 60m before the Habit is scheduled to begin.",
  },
  HIGH: {
    label: "Most defensive",
    description:
      "Marks the Habit as busy when one slot remains for its maximum duration, or 24h before the Habit is scheduled to begin.",
  },
  MAX: { label: "Always busy", description: "Habits will always show as busy and unavailable time on your calendar." },
};

export const HABIT_DEFENSIVENESS_ORDER: DefenseAggression[] = [
  DefenseAggression.None,
  DefenseAggression.Low,
  DefenseAggression.Default,
  DefenseAggression.High,
  DefenseAggression.Max,
];

export type HabitType = Override<
  HabitDto,
  {
    readonly effectivePriority?: Smurf;

    readonly created: Date;
    readonly updated?: Date;
    readonly deleted?: boolean;

    index: number;
    enabled: boolean;
    title: string;
    eventCategory: PrimaryCategory;
    eventColor?: EventColor;
    daysActive?: DayOfWeek[];
    idealDay?: DayOfWeek | null;
    recurrence: Recurrence | null;
    priority?: Smurf;
    snoozeUntil?: Date | null;
    defenseAggression: DefenseAggression;
    eventSubType: EventSubType;
    timesPerPeriod?: number;
  }
>;

export class Habit implements HabitType {
  readonly id: number;
  readonly effectivePriority?: Smurf;

  readonly created: Date;
  readonly updated?: Date;
  readonly deleted?: boolean;

  title: string;
  index: number;
  enabled: boolean;
  eventCategory: PrimaryCategory;

  campaign?: string;
  defendedDescription: string;
  additionalDescription: string;
  windowStart: string;
  windowEnd: string;
  idealTime: string;
  idealDay: DayOfWeek | null;
  durationMin: number;
  durationMax: number;
  timesPerPeriod?: number;
  daysActive?: DayOfWeek[];
  recurrence: Recurrence | null;
  invitees: ThinPersonDto[];
  alwaysPrivate: boolean;
  defenseAggression: DefenseAggression;
  elevated: boolean;
  eventColor?: EventColor;
  adjusted: boolean;
  notification: boolean;
  eventSubType: EventSubType;
  reservedWords: string[];
  autoDecline: boolean;
  autoDeclineText: string;

  snoozeUntil?: Date | null;

  type: AssistTypeDto;

  recurringAssignmentType: RecurringAssignmentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location?: string | null | undefined;
  eventFilter?: object | undefined;

  // TODO (IW): Find a better place for this (and Task.getColor, Calendar.getColor)
  static getColor(user: User, habit: Habit): EventColor | undefined {
    return !!habit.eventColor && habit.eventColor?.key !== EventColor.Auto.key
      ? habit.eventColor
      : EventColor.getColor(user, habit.eventCategory);
  }
}

export function dtoToHabit(dto: HabitDto): Habit {
  return {
    ...dto,
    id: !!dto.id ? (dto.id as number) : undefined, // strip 0 ids (long id == 0)
    eventCategory: !!dto.eventCategory ? Category.get(dto.eventCategory as unknown as string) : undefined,
    eventColor: !!dto.eventColor ? EventColor.get(dto.eventColor) : EventColor.Auto,
    recurrence: !!dto.recurrence ? Recurrence.get(dto.recurrence) : null,
    daysActive: dto.daysActive as unknown as DayOfWeek[],
    idealDay: dto.idealDay as unknown as DayOfWeek,
    snoozeUntil: nullable(dto.snoozeUntil, strToDate),
    timesPerPeriod: dto.timesPerPeriod,
    defenseAggression: dto.defenseAggression as unknown as DefenseAggression,
    created: strToDate(dto.created),
    updated: strToDate(dto.updated),
    eventSubType: EventSubType.get(dto.eventSubType),
  } as Habit; // TODO (IW) Ditch casting once swagger required/optional fields are configured properly
}

export function habitToDto(habit: Partial<Habit>): Partial<HabitDto> {
  const data: Partial<HabitDto> = {
    ...habit,
    eventCategory: habit.eventCategory?.toJSON() as unknown as HabitDto["eventCategory"],
    eventColor: (EventColor.Auto === habit.eventColor ? null : habit.eventColor?.toJSON()) as HabitDto["eventColor"],
    // IMPORTANT: null means "clear it out", undefined means "don't change it" (see RAI-3230)
    // TODO: we really need to break out separate types for patch, create, get etc etc
    recurrence: (habit.recurrence === null ? null : habit.recurrence?.key) as RecurrenceDto,
    daysActive: habit.daysActive as unknown as DayOfWeekDto[],
    idealDay: habit.idealDay as unknown as DayOfWeekDto,
    // TODO ask Patrick why? we really need to stick to a format (ma)
    snoozeUntil: nullable(habit.snoozeUntil, dateToStr),
    timesPerPeriod: habit.timesPerPeriod,
    defenseAggression: habit.defenseAggression as unknown as DefenseAggressionDto,
    created: dateToStr(habit.created),
    updated: dateToStr(habit.updated),
    eventSubType: habit.eventSubType?.key,
  };

  return data;
}

const DailyHabitSubscription = {
  subscriptionType: SubscriptionType.DailyHabit,
};

export class HabitsDomain extends TransformDomain<Habit, HabitDto> {
  resource = "Habit";
  cacheKey = "habits";
  pk = "id";

  public serialize = habitToDto;
  public deserialize = dtoToHabit;

  watchWs$ = pipe(
    this.ws.subscription$$(DailyHabitSubscription),
    filter((envelope) => !!envelope.data),
    map((envelope) => envelope.data),
    deserialize(this.deserialize)
  );

  watchAll$ = pipe(
    merge([this.upsert$, this.watchWs$]),
    map((items) => this.patchExpectedChanges(items))
  );

  list$$ = () =>
    pipe(
      fromPromise(this.list()),
      map((items) => this.patchExpectedChanges(items))
    );

  listAndWatch$$ = () => {
    return pipe(
      concat<Habit[] | null>([this.list$$(), this.watchAll$]),
      upsert((h) => this.getPk(h)),
      map((items) => [...items])
    );
  };

  watchId$$ = (id: number) => {
    return pipe(
      this.listAndWatch$$(),
      map((items) => items?.find((i) => i.id === id))
    );
  };

  list = this.deserializeResponse(this.api.assist.getDailyHabits);

  get = this.deserializeResponse(this.api.assist.getDailyHabit);

  create = this.deserializeResponse((habit: Habit) => {
    const notificationKey = this.generateUid("create");

    this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

    return this.api.assist
      .create(this.serialize(habit) as HabitDto, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  patch = this.deserializeResponse((habit: Partial<Habit>) => {
    // exclude additionalDescription when updating until that feature has a field in the UI
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, additionalDescription, ...rest } = habit;
    const notificationKey = this.generateUid("patch", habit);

    this.expectChange(notificationKey, id!, rest, true);

    return this.api.assist
      .patch(id!, this.serialize(rest) as HabitDto, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  delete = (id: number) => {
    const notificationKey = this.generateUid("delete", id);

    this.expectChange(notificationKey, id, { deleted: true });

    return this.api.assist
      .delete1(id, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  createDefaults = (data: { lunch: boolean; catchup: boolean }) => {
    // TODO createDefaultHabits types are not cast on the server / OpenAPI (ma)
    return this.api.assist.createDefaultHabits(data);
  };

  getHabitTemplate = this.typedManageErrors(async (templateKey: HabitTemplateKey) => {
    const template = await this.api.assist.getHabitTemplate({ templateKey: templateKey as HabitTemplateKeyDto });
    return dtoToHabitTemplate(template);
  });

  getHabitTemplates = this.typedManageErrors(async (role?: UserProfileRole, department?: UserProfileDepartment) =>
    (
      await this.api.assist.getHabitTemplates({
        role: role ? (role as UserProfileRoleDto) : null,
        department: (department as UserProfileDepartmentDto) || undefined,
      })
    ).map(dtoToHabitTemplate)
  );

  createHabitTemplates = this.typedManageErrors(
    async (templates?: HabitTemplateKey[]) =>
      await this.api.assist.createHabitTemplates({ templates: templates as HabitTemplateKeyDto[] })
  );
}

export const defaultHabit: Partial<Habit> = {
  title: "",
  daysActive: Weekdays,
  eventCategory: PrimaryCategory.Personal,
  eventColor: EventColor.Auto,
  enabled: true,
  defenseAggression: DefenseAggression.Default,
  durationMin: 15,
  durationMax: 120,
  windowStart: "08:00:00",
  windowEnd: "18:00:00",
  idealTime: "09:00:00",
  recurrence: Recurrence.Weekly,
  alwaysPrivate: false,
  invitees: [],
  index: 0,
};

export function userDefaultHabit(user?: User | null, overrides: Partial<Habit> = {}): Partial<Habit> {
  // additionalDescription gets used in google cal, and that requires no line breaks or extra spaces, so ensure its clean here
  if (overrides.additionalDescription) {
    overrides.additionalDescription = overrides.additionalDescription
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/[\t ]+\</g, "<")
      .replace(/\>[\t ]+\</g, "><")
      .replace(/\>[\t ]+$/g, ">");
  }

  return { ...defaultHabit, ...overrides };
}
