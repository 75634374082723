import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { ChangeEvent, ReactElement, useMemo, useState } from "react";
import { AsyncButton } from "../../components/forms/AsyncButton";
import { ReasonTip } from "../../components/ReasonTip";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { DevConfigAction } from "../dev.types";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexDirection: "column",
      alignItems: "stretch",
      background: theme.colors.white,
      borderRadius: theme.spacing(2),
      margin: theme.spacing(),
      width: "auto",
    },
    header: {
      cursor: "pointer",
    },
    collapseInner: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
    formControl: {
      width: "100%",
    },
    arg: {
      gap: theme.spacing(),
      justifyContent: "start",
      minWidth: 200,
    },
    argInput: {
      width: "100%",
    },
    argInputNoStretch: {
      alignSelf: "start",
    },
    runWrapper: {
      alignSelf: "center",
    },
    run: {},
  }),
  {
    classNamePrefix: "DevActionListItem",
  }
);

export type DevActionListItemJSSClassKey = keyof ReturnType<typeof useStyles>;

export type DevActionListItemChangeHandler<K extends string, D extends Record<string, unknown>> = (
  key: K,
  data: D
) => unknown;

export type DevActionListitemRunHandler<K extends string> = (key: K) => unknown;

export type DevActionListItemProps<K extends string, D extends Record<string, unknown>> = {
  classes?: Partial<ClassNameMap<DevActionListItemJSSClassKey>>;
  className?: string;
  action: DevConfigAction<"client", K>;
  data: D;
  onDataChange: DevActionListItemChangeHandler<K, D>;
  onRun: DevActionListitemRunHandler<K>;
};

export const DevActionListItem = <K extends string, D extends Record<string, unknown>>({
  className,
  classes: extClasses,
  action: { label, args, key },
  data,
  onDataChange,
  onRun,
}: DevActionListItemProps<K, D>): ReactElement<DevActionListItemProps<K, D>> => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  /********************/
  /*     useState     */
  /********************/

  const [open, setOpen] = useState(false);

  /********************/
  /* useMemo & consts */
  /********************/

  const allArgsSet = useMemo(() => {
    if (!args) return true;
    return args.every((arg) => data[arg.key] !== undefined);
  }, [args, data]);

  /********************/
  /*    useCallback   */
  /********************/

  const toggleOpen = useCallbackSafeRef(() => setOpen(!open));
  const setDataKey = useCallbackSafeRef((argKey: string, value: unknown) =>
    onDataChange(key, {
      ...data,
      [argKey]: value,
    })
  );

  const run = useCallbackSafeRef(() => onRun(key));

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <ListItem className={clsx(classes.root, className)}>
      <div className={classes.header} onClick={toggleOpen}>
        {label}
      </div>
      <Collapse in={open} classes={{ wrapperInner: classes.collapseInner }}>
        {args && (
          <List>
            {args.map(({ key, label, type }) => {
              const wrapInFormControlLabel = (control: ReactElement) => (
                <FormControlLabel className={classes.formControl} label={label} control={control} />
              );

              const wrapInFormControl = (control: ReactElement) => (
                <FormControl className={classes.formControl}>
                  <InputLabel>{label}</InputLabel>
                  {control}
                </FormControl>
              );

              return (
                <ListItem key={key} className={classes.arg}>
                  {(() => {
                    const value = data[key];
                    const handleChange = (e: ChangeEvent<{ value: unknown }>) => setDataKey(key, e.target.value);

                    switch (typeof type) {
                      case "object":
                        switch (type.specialType) {
                          case "select":
                            return wrapInFormControl(
                              <Select
                                className={classes.argInput}
                                value={value || ""}
                                onChange={handleChange}
                                placeholder="Select one..."
                              >
                                {type.values.map((argValue) => (
                                  <MenuItem key={argValue} value={argValue}>
                                    {argValue}
                                  </MenuItem>
                                ))}
                              </Select>
                            );
                          default:
                            return <>special type {type.specialType} not yet supported</>;
                        }
                      case "string":
                        switch (type) {
                          case "string":
                            return wrapInFormControl(
                              <TextField
                                className={classes.argInput}
                                value={value}
                                onChange={handleChange}
                                label={label}
                              />
                            );
                          case "integer":
                            return wrapInFormControl(
                              <TextField
                                className={classes.argInput}
                                type="number"
                                value={value}
                                onChange={handleChange}
                              />
                            );
                          case "boolean":
                            return wrapInFormControlLabel(
                              <Checkbox
                                className={classes.argInputNoStretch}
                                checked={value as boolean}
                                onChange={(e) => setDataKey(key, e.target.checked)}
                              />
                            );
                          default:
                            return <>type {type} not yet supported</>;
                        }
                      default:
                        return <>found an unexpected action of type {typeof type}</>;
                    }
                  })()}
                </ListItem>
              );
            })}
          </List>
        )}
        <ReasonTip reasons={[{ test: !allArgsSet, message: "Please set all arguments" }]}>
          {(disabled) => (
            <span className={classes.runWrapper}>
              <AsyncButton className={classes.run} disabled={disabled} variant="contained" onClick={run} size="small">
                run
              </AsyncButton>
            </span>
          )}
        </ReasonTip>
      </Collapse>
    </ListItem>
  );
};
