import { ClickAwayListener, Grow, makeStyles, Paper, Popper as MuiPopper, PopperProps } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { createElement, useEffect, useMemo, useRef, useState, VFC } from "react";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { Override } from "../../types";
import { QuestGenericPopperContent } from "./QuestGenericPopperContent";
import { GenericQuestStepConfig, QuestStepProps } from "./quests.types";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: theme.spacing(1.5), // This creates offset from popper to target
      zIndex: 1300,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.71em",
        marginLeft: 12,
        marginRight: 12,
        "&::before": {
          transformOrigin: "0 100%",
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.71em",
        marginLeft: 12,
        marginRight: 12,
        "&::before": {
          transformOrigin: "100% 0",
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 12,
        marginBottom: 12,
        "&::before": {
          transformOrigin: "100% 100%",
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 12,
        marginBottom: 12,
        "&::before": {
          transformOrigin: "0 0",
        },
      },
    },
    paper: {
      backgroundColor: theme.colors.logo.corn,
      borderRadius: theme.shape.borderRadius * 2.5,
      overflow: "hidden",
      maxWidth: 285,
    },
    boxShadow: {
      border: "2px solid rgb(255, 197, 80)",
    },
    arrow: {
      overflow: "hidden",
      position: "absolute",
      width: "1em",
      height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: "border-box",
      color: theme.colors.logo.corn,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        boxShadow: theme.shadows[1],
        backgroundColor: "currentColor",
        transform: "rotate(45deg)",
      },
    },
  }),
  {
    classNamePrefix: "QuestStepPopper",
  }
);

export type QuestStepPopperJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestStepPopperProps = Override<
  Omit<PopperProps, "children">,
  {
    classes?: Partial<ClassNameMap<QuestStepPopperJSSClassKey>>;
    className?: string;
    stepConfig: GenericQuestStepConfig;
    onClose: () => void;
  }
>;

export const QuestStepPopper: VFC<QuestStepPopperProps> = ({
  className,
  classes: extClasses,
  stepConfig,
  placement = "left",
  onClose,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const popperRef = useRef(null);

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const component = useMemo(() => stepConfig?.component || QuestGenericPopperContent, [stepConfig]);

  const handleClose = useCallbackSafeRef(() => {
    onClose?.();
  });

  const handleClickAway = useCallbackSafeRef(() => {
    if (stepConfig.type !== "action") onClose?.();
  });

  /**
   * Effect to reposition the popper every 750 ms when content changes
   */
  useEffect(() => {
    const timer = setInterval(() => {
      if (popperRef.current) {
        // The current ref here is a popper js instance but it is not available via mui 
        (popperRef.current as { update: () => void }).update();
      }
    }, 750);
    return () => clearInterval(timer);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MuiPopper
        popperRef={popperRef}
        className={clsx(classes.root, className)}
        placement={placement}
        modifiers={{
          flip: { enabled: true },
          preventOverflow: {
            enabled: true,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        transition
        {...rest}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <>
              <span className={classes.arrow} ref={setArrowRef} />
              <Paper
                className={clsx(classes.paper, { [classes.boxShadow]: !!stepConfig.popoverConfig?.img })}
                elevation={10}
              >
                {createElement<QuestStepProps>(component, {
                  onComplete: handleClose,
                  onClose: handleClose,
                  stepConfig,
                })}
              </Paper>
            </>
          </Grow>
        )}
      </MuiPopper>
    </ClickAwayListener>
  );
};
